@import "./scss/privacy-policy.scss";
 

.react-loading-skeleton {
  --pseudo-element-display: block !important;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-text {
  width: 50%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

body {
  font-family: "Poppins";
  color: #1a1b20;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

p {
  opacity: 1;
  font-size: 18px;
  line-height: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.custom-navbar {
  padding: 21px 0;
  transition: all 0.4s;
}

.App-header {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  &.scrolled {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 500;
    background: #fff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.5s ease;
    -moz-transition: position 10s;
    -ms-transition: position 10s;
    -o-transition: position 10s;
    transition: all 0.5s ease;
    animation: smoothScroll 1s forwards;

    .custom-navbar {
      padding: 15px 0;
    }
  }
}

.App-header.scrolled + .main {
  margin-top: 85px;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0px);
  }
}

.btn:focus,
.btn:active:focus {
  box-shadow: none;
  outline: none;
}

.btn-primary {
  background: transparent
    linear-gradient(270deg, rgba(113, 89, 214, 1) 0%, rgba(6, 185, 187, 1) 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  border: 1px solid #0aaec1;
  font-size: 16px;
  line-height: 26px;
  padding: 10px 24px;

  &:hover {
    background: #fff;
    color: #0aaec1;
    border-color: #0aaec1;
  }
}

.btn-primary.btn-reverse {
  background: #fff;
  color: #00777f; 

  &:hover {
    background: transparent
      linear-gradient(
        270deg,
        rgba(113, 89, 214, 1) 0%,
        rgba(6, 185, 187, 1) 100%
      )
      0% 0% no-repeat padding-box;
    color: #fff;
  }
}

.plans{
   width: 100%;
   justify-content: center !important;
   align-items: center !important; 
   display: flex;
   &::before {
    background-image: url(../public/assets/images/triangles.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: -1;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.plans-cards{
  width: 100%;
  justify-content: center !important;
  align-items: center !important;
  display: table; 
}

.btn-premium {
  background: transparent
    linear-gradient(270deg, rgba(113, 89, 214, 1) 0%, rgba(6, 185, 187, 1) 100%)
    0% 0% no-repeat padding-box;
  opacity: 1;
  border:0;
  border-radius: 3px;
  font-size: 12px;
  line-height: 15px;
  padding: 5px 12px;
  color: #fff; 
  display: inline-flex;
  align-items: center;  
  overflow: hidden;
  border-radius: 50px;
  span{
    margin-right: 10px;
  }
  svg{
        -webkit-animation: animName 2s linear infinite;
          animation: animName 2s linear infinite;
  }
  
}
    
.plans-text-color{ 
    -webkit-background: transparent linear-gradient(270deg, #7159d6 0%, #06b9bb 100%) 0% 0% no-repeat;
    background: transparent linear-gradient(270deg, #7159d6 0%, #06b9bb 100%) 0% 0% no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.gradient-text {
  -webkit-background: transparent linear-gradient(270deg, #7159d6 0%, #06b9bb 100%) 0% 0% no-repeat;
  background: transparent linear-gradient(270deg, #7159d6 0%, #06b9bb 100%) 0% 0% no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

// section.pricing {
//   background-image: url(../public/assets/images/triangles.jpg);
// }

 

@keyframes animName {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
  margin: 1.5rem 0;
}

.hr-title{
  margin: 0.5rem !important;
}

.pricing .card-title { 
  font-size: 1.3rem;
  letter-spacing: .1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%; 
  letter-spacing: .1rem;
  font-weight: 500;
  padding: 0.6rem; 
  transition: all 0.2s;
  max-width: 375px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* Hover Effects on Card */

 


.heading-main {
  font-size: 58px;
  font-weight: 500;
  line-height: 66px;
}

.heading-medium {
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 16px;
}

.form-control {
  background: #f2f2f2;
  opacity: 1;
  border-width: 1px;
  border-style: solid;
  padding: 12px 20px;
  font-size: 16px;
  color: #9ea0a7;
  border-color: #e1e1e1;
  &::placeholder {
    color: #9ea0a7;
  }

  &:focus {
    outline: none;
    color: #0aaec1;
    border-color: #0aaec1;
    box-shadow: none;
  }
}

.footer {
  .nav-link {
    color: #252342;
  }
}

.copy-right {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.breadcrumbs {
  background: #faf9ff;
  padding: 20px 10px;
  margin-bottom: 30px;

  .bread-items {
    font-size: 24px;
    line-height: 35px;
  }
}


.sidebar-bread{
  text-align: left;
  padding: 10px 20px;
  .bread-items{
      font-size: 18px;
        line-height: 30px;
  }
}
.h-100vh{
  height: 100vh;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

// float input feild

.floating-group {
  position: relative;
  margin-bottom: 16px;

  .form-control {
    box-shadow: none;
    border-radius: 0.375rem;
    padding: 15px 20px;
    color: #3a3a3a;
    border-width: 1px;
    border-color: #e1e1e1;
    border-style: solid;
    background-color: #fff;

    &:focus {
      outline: none;
      color: #0aaec1;
      border-color: #0aaec1;
      box-shadow: none;
      ~ .float-label {
        top: -7px;
        font-size: 12px;
        line-height: 16px;
        color: #6b6b6b;
        background: #fff;
      }
    }
  }

  .float-label {
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    top: 14px;
    padding: 0 5px;
    background: none;
    font-size: 14px;
    line-height: 20px;
    color: #6b6b6b;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
    white-space: nowrap;
  }

  .form-control:not(:placeholder-shown) ~ .float-label {
    top: -7px;
    font-size: 12px;
    line-height: 16px;
    color: #6b6b6b;
    background: #fff;
  }

  span.info-icon {
    margin-top: -2px;
  }
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: none;
}
// float input feild end
#react-select-2-listbox {
  background: #fff;
  z-index: 50;
}
.fv-help-block {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: -6px;
}
.h-auto{
  height: auto !important;
}

.logo.d-flex{
  flex-direction: column;
}
.min-w-160{
  min-width:160px !important;
}

.bg-theme-gradient{
  background: linear-gradient(270deg, rgb(113, 89, 214) 0%, rgb(6, 185, 187) 100%) 0% 0% no-repeat padding-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

input[type=number] {
-moz-appearance: textfield;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}

.language-selection {
  position: relative;
  margin-right: 15px;

  .carate {
    position: absolute;
    right: 11px;
    top: 10px;
    z-index: 1;

    &::after {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border-right: 0.1em solid black;
      border-top: 0.1em solid black;
      transform: rotate(135deg);
    }
  }
}

select{
  
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    
}
.select-language {
  border: 0;
  border-radius: 0.25rem;
  position: relative;
  padding: 12px;
  padding-right: 35px;
  cursor: pointer;
  box-shadow: none;
  z-index: 15;
  background: transparent;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none; 
  &:focus {
    border-color: #0aaec1;
    outline: none;
    box-shadow: none;
  }

}


.inner-language {
  .select-language {
    height: 74px;
    margin-top: -26px;
    font-size: 14px;

  }

  svg {
    fill: #000;
  }

  &:hover {
    .select-language {
      color: #0aaec1;
    }

    svg {
      fill: #0aaec1;
    }
  }
}
// All media css start here

@media only screen and (min-width: 992px) {
  .bg-sky-blue {
    background: #d4e9ff;
    opacity: 1;
  }

  .bg-light-blue {
    background: #eae6ff;
  }


  .pricing-modal{
    .modal-lg{
          max-width: 920px;
    }
  }
  .rounded-bordered{
    border: 1px solid #0aaec1;
    border-radius: 12px;
  }
}

@media only screen and (max-width: 991px) {
  .mb-40 {
    margin-bottom: 20px;
  }

  .heading-medium {
    font-size: 40px;
    line-height: 52px;
  }

  .breadcrumbs {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }

  .auth-inner {
    .row > * {
      padding-right: 12px;
      padding-left: 12px;
    }
  }

  p {
    font-size: 16px;
    line-height: 28px;
  }

  .heading-medium {
    font-size: 32px;
    line-height: 45px;

    br {
      display: none;
    }
  }

  .copy-right {
    font-size: 14px;
    line-height: 18px;
  }

  .breadcrumbs {
    .bread-items {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .fs-24 {
    font-size: 24px;
    line-height: 36px;
  }
}

.service-label {
  font-size: 14px;
  line-height: 16px;
}
 
// crop image css start

 
.master-container{
  position: relative; 
    display: flex;
    flex-direction: column; 
  .controls {
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-left: -15px;
    margin-right: -15px;
    justify-content: center;
    }
}

.crop-container { 
  height: 220px;
  position: relative;
  margin-bottom: 30px;
}
.handle {
  width: 100%;
  padding: 0 30px;
}
.crop-content-center{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.cta {
  background-color: #F60;
  color: #fff; 
  font-size: 16px;
  line-height: 26px;
  padding: 10px 24px;
}

// crop image css end
.services-fields {
  position: relative;
  z-index: 20;
} 

// modal table css
.review-tabel.table>:not(:first-child){
border-top:0;
}
.review-tabel{
  td.r-name {
      width: 30%;
    }
    td {
      border: 0;
    }
}
 
.bg-lighter{
      background: #f8fcff;
}




// Review modal css start here
.review-modal {
  .model-header {
      padding: 2rem;
  }
  .modal-body{
      padding: 2rem;
  }
  .form-control{
      padding: 12px 20px;
      margin-bottom: 12px;
  }
    .floating-group { 
      margin-bottom: 10px;
    }
 
.fv-help-block{
  color: #dc3545;
      font-size: 12px;
}
}




.tabBtn {
  background: #fff;
  color: rgb(117, 117, 117);
  border: none;
  margin: 0px 5px;
  padding: 0;
  font-weight: 600;
}

.tabBtn.active { 
  color: #6079bc;
}
.tabBtn{
  border: 0;
  &:focus{
    outline: none;
    border: 0;
    box-shadow: none;
  }
}

// Loader
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}


.loader-overlay .loader-body{
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  align-items: center;
  padding: 20px 10px;
  border-radius: 10px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.qr-icon{
  min-width: 70px;
  cursor: pointer;
  svg{
    padding: 6px; 
    background-color: #fff;
    border: 1px solid var(--theme05);
    border-radius: 4px;
  }
 }
 .powered-block{
  font-size: 12px; 
  padding-left: 0 !important;
  margin-top: 10px;
  a{
    color: var(--theme05);
  }
    a.create-link{
      border-radius: 15px;
      border: 1px solid var(--theme05);
      padding: 0 10px;
      -webkit-transition: all .15s ease-in-out;
      transition: all .15s ease-in-out;
      white-space: nowrap;
      margin-left: 7px;
      &:hover,&:focus{
        color: #fff;
        background-color:var(--theme05); 
      }
    }
 }

//  .social-media{
//   margin-bottom: 13px !important;
//  }

// action button css start here

.addcontact-btn{
  background-color: #fff;
  box-shadow: none;
  border: 0;
  outline: none;
  border-radius: 3px;
  padding: 3px 8px 4px;
  width: 100%;
  border: 1px solid var(--theme05);
  margin-bottom: 8px;
  &:focus,&:active,&:hover{
    box-shadow: none;
    outline: none; 
    background-color: transparent;
  }
}

.social-media-wrap{
  display: flex;
  flex-wrap: wrap;
  .social-media {
    -moz-box-flex: 1; 
      -webkit-box-flex: 1; 
      flex: 1;
    }
}
 
 .share-icon-wrap{
  display: flex; 
  .review-button{
    padding-right: 0px !important;
  }
 }





//  All media start here

@media only screen and (min-width: 576px){
  .review-modal{
    .modal-dialog {
      max-width: 600px;
    }
  }

}

@media only screen and (max-width: 575px){
.review-modal {
    .model-header ,.modal-body{
      padding: 1.2rem;
    } 
    
  }
    .handle { 
      padding: 0 14px;
    }
        .tabBtn{
          font-size: 15px;
        } 
}
// Review modal css end here





// Logo add all template css start

.logo-bg-shape{
  background: #fff; 
  position: absolute;  
  text-align: center; 
  z-index: 100; 
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
    padding: 6px;
}
.card-logo-square{
  height: 50px;
  width: 90px;
  -webkit-clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 75% 100%, 0 100%);
  
  &.shape-right {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%); 
          padding-left: 12px;
    }
}
.card-logo-rectangle{ 
  height: 50px;
  width: 176px;
  -webkit-clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);  
  clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
  &.shape-right {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 13% 100%); 
    width: 50%;
    padding-left: 27px;
  }

}
 


.shape-right{
  right: -1px;
  top: 0;
  
  
}

.shape-left{
  left: 0;
  top: 0;
  padding-right: 20px;
}
.logo-bg-shape{
  &.shape-center { 
        clip-path: polygon(20% 100%, 80% 100%, 100% 0, 0 0);
        height: 50px;
       width: 100px;
       padding: 8px; 
    }
    &.rectangle-width{
          width: 185px;
          padding: 5px 17px 5px;
          clip-path: polygon(10% 100%, 90% 100%, 100% 0, 0 0);
    }
}
.shape-center{
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);   
}


// Logo add all template css end

// Ribbon css start here
     
$color_1: #fff;
$background-color_1: transparent linear-gradient(270deg, #7159d6 0%, #06b9bb 100%) 0% 0% no-repeat;
$border-top-color_1: transparent;
$border-left-color_1: transparent;

/* top left*/
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  z-index: 99;
  pointer-events: none; 

  &::before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #443add;
  }

  &::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #1aabbf;
  }

  span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    background: $background-color_1;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
    color: $color_1; 
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-transform: uppercase;
    text-align: center;
  }


     .card-price {
      font-size: 1.3rem;
      margin: 0;
          font-weight: 600;
    }
}

.ribbon-top-left {
  top: -10px;
  left: -10px;

  &::before {
    border-top-color: $border-top-color_1;
    border-left-color: $border-left-color_1;
    top: 0;
    right: 0;
  }

  &::after {
    border-top-color: $border-top-color_1;
    border-left-color: $border-left-color_1;
    bottom: 0;
    left: 0;
  }

  span {
    right: -18px;
    top: 30px;
    transform: rotate(-45deg);
  }
}


// Ribbon css end here



// Slide up  content css
 
.show-detail{ 
        bottom: -100%; 
        height: 100%;
        left: 0%;
        text-align: center;
        position: absolute;
        transition: bottom 0.5s ease;
        z-index: 50;
        width: 100%;
      background: #fff; 
      padding: 0 15px; 
      padding-top: 20px;
          border-top: 1px solid #0aaec1;
      .rupee-sign{
          margin-top: -13px;
          font-size: 27px;
          line-height: 22px;
      }
}

.plans-cards{ 
  .template-main{
    position: relative;
    z-index: 1;
  }
  .card-body{ 
    overflow: hidden; 
      position: relative;
    &:hover{
      .show-detail{  
            bottom: -86%;
      }
    }
  }
}
// Slide up cs end
.razorpay-page{
  .accordion-collapse {
      padding: 0 18px 20px;
    }
}
.overlay
{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 9999;
}
.custom-email
{
  max-width:230px;
  overflow: hidden;
}
.custom-website-Link
{
  max-width: 230px;
  overflow: hidden;
}
.custom-companyName6
{
  max-width: 163px;
  overflow: hidden;
}
.custom-companyName7
{
  max-width: 183px;
  overflow: hidden;
}
.custom-companyName8
{
  max-width: 255px;
  overflow: hidden;
}
.custom-companyName9
{
  max-width: 175px;
  overflow: hidden;
}
.custom-userName
{
  max-width: 183px;
  overflow: hidden;
}
.custom-userName-centeruser
{
  max-width: 230px;
  overflow: hidden;
}
.custom-address
{
  max-width: 230px;
  overflow: hidden;
}