.card-inner {
  box-shadow: 0px 8px 16px #0000001f;
  opacity: 1;
  background: #fff;
    padding: 56px 20px 20px;
  border-radius: 30px;
  width: calc(100% - 80px);
  margin: 0 auto;
  position: relative;
  margin-top: -65px;
  margin-bottom: 25px;
  z-index: 20;
  min-height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name-cols4 {
  color: #1e1e1e;
  .user-name {
    font-size: 24px;
    line-height: 35px;
    font-weight: 500;
  }
  .user-post {
    font-size: 10px;
    line-height: 11px;
    font-weight: 500;
  }
  .c-name {
    font-size: 12px;
    line-height: 13px;
    font-style: italic;
  }
}

.curve-header {
}
.user-avtar-round { 
  padding: 64px 0 15px;
  .user-img {
    z-index: 50;
  }
}
 .curve-img-bottom {
   margin-top: 0;
   position: absolute;
   left: 0;
   top:0;
   width: 100%;
   height: 100%;
   svg{
    fill: var(--theme05);
   }
 }
 @media (max-width: 767px){

    .card-inner{
          width: calc(100% - 40px);
    } 
 }