.select-cards {
  &.scroll-sticky { 
    border-radius: 15px;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    // padding: 20px 20px 10px;
    box-shadow: inset 0.2rem 0.2rem 1.5rem #99ace2, inset -1.2rem -7.2rem 0.5rem #fff;
    overflow: hidden;
  }

  .App { 
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 15px 12px;   
    scrollbar-color: var(--theme05) #F5F5F5; 
    &::-moz-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: #F5F5F5;
      }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      background-color: #F5F5F5;
    }
    

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }
    &::-moz-scrollbar {
      width: 6px;
      background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb  {
      border-radius: 6px;
       box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      background-color: var(--theme05);
    }
        &::-moz-scrollbar-thumb {
          border-radius: 6px;
           box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: var(--theme05);
        }
      
       
     
  }

  .card-item {
    margin-bottom: 30px;
    padding: 0 20px;

    img {
      box-shadow: 5px 5px 10px #00000029;
    }
  }

  .swiper-slide {
    height: 100% !important;
  }


}

.preview-final .template-main {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border: 1px solid #d6d6d6;
  padding: 40px 24px 40px;
}

.profile-preview-page {
  padding: 30px 0 30px;
}

.three-col-view {
  display: grid;
  // grid-template-columns: minmax(300px, auto) 1fr 1fr;
  // grid-template-columns: repeat(3, 1fr);
  grid-template-columns: 300px 1fr 1fr;
  grid-gap: 40px;
  -webkit-grid-gap: 40px;
}

 


.heading {
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 30px;
}

.cell-items {
  .color-plates {
    padding: 0;
    border: 0;
    box-shadow: none;
  }
}

.circle-picker {
  margin-right: -8px !important;

  span {
    padding-right: 15px;

    div {
      margin-right: 0 !important;
    }
  }
}



section.slider {
  position: relative;
}

 
.slider .swiper-container1 {
  width: 100%;
  height: 100%;
}
 
.slider .swiper-container2 {
  width: 100%;
  height: 100%;
}


.slider__flex {
  display: flex;
  align-items: flex-start;

}


.slider__col {
  display: flex;
  flex-direction: column;
}


.slider__prev,
.slider__next {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
}

.slider__prev {
  position: absolute;
  bottom: 0;
  left: 0;
}

.slider__next {
  position: absolute;
  top: 0;
  left: 0;
}

.slider__prev:focus,
.slider__next:focus {
  outline: none;
}
 
.slider__image.active {
  border-color: #000;
  -webkit-box-shadow: 0px 5px 14px -6px rgb(28, 28, 28);
  -moz-box-shadow: 0px 5px 14px -6px rgb(28, 28, 28);
  box-shadow: 0px 5px 14px -6px rgb(28, 28, 28);
  transition: all .4s ease-in-out;
  border-width: 2px;
}


.slider__thumbs .swiper-slide-thumb-active .slider__image {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}


.slider__images {
  width: 100%;

  .slide-item {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 20px;
    position: relative;
  }

  .badge-active {
    position: absolute;
    right: 26px;
    top: 7px;
    z-index: 12;
      background-color: #fff;
      font-size: 12px;
      line-height: 14px;
      padding: 4px 10px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      border: 1px solid #04bf04;
      padding-right: 20px;
      

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      background-color: #04bf04;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      top: 6px;
      z-index: 20;
      animation: flash 800ms ease infinite alternate;
      -webkit-animation: flash 800ms ease infinite alternate;
    }
  }
}

.flash {
  animation: flash 500ms ease infinite alternate;
}

@keyframes flash {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}

.slider__image {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  border: 2px solid #b5cfff;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #b5cfff;
  transition: all .4s ease-in-out;
  cursor: pointer;
}

.color-choose-mobile {
  display: block;
  position: static;

}

.color-choose-wrap {
  display: none;
}


.color-section {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  border: 1px solid #d6d6d6;
  padding: 30px 20px 30px;


}

.color-plates {
  .circle-picker span {
    // padding-right: 12px;
  }
}

// mobile sidebar temp preview css


.side-temp-preview {
  .temp-list {
    display: block;
    background-color: #fff;
    width: 150px;
    position: fixed;
    right: -160px;
    top: 85px;
    z-index: 200;
    padding: 15px;
    border-radius: 15px 0 0 15px; 
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    transition: all 0.4s ease-in-out;

    &.show-preview {
      right: 0;
    }
  }

  .preview-btns {
    position: fixed;
    right: -2px;
    top: 138px;
    z-index: 200;
    padding: 6px 19px !important;
    border-radius: 24px 0 0 24px;
    background: #fff; 
    box-shadow: #7159d6 1px 0 4px 0px;
    background-color: #fff !important;
    max-width: 66px;
  } 

  .select-cards .App {
    padding: 9px 3px;
    height: calc(100vh - 226px);
  }

  .select-cards.scroll-sticky {
    padding: 0 0 0; 
    height: calc(100vh - 220px);
  }

  .slider__images .badge-active::after{
    font-size: 10px;
    line-height: 12px;
    padding: 5px 7px;
    padding-right: 20px;
  }

}

//  All media start here
@media (min-width: 768px) {
  .color-btn {
    display: none;
  }

  .side-temp-preview {
    display: none;
  }
    .select-cards .App{
          max-height: calc(100vh - 112px);
    }
}

@media (max-width: 1199px) {
  .three-col-view {
    grid-template-columns: 150px 1fr 1fr;
    grid-gap: 20px;
    -webkit-grid-gap: 40px;
  }

  .select-cards.scroll-sticky {
    padding: 16px 0 0;
  }
}

@media (max-width: 991px) {
  .three-col-view {
    grid-gap: 20px;
    -webkit-grid-gap: 20px;
  }

  .heading {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 26px;
  }

  .three-col-view {
    grid-template-columns: 124px 1fr 1fr;
    grid-gap: 16px;
    -webkit-grid-gap: 16px;
  }

  .slider__images .slide-item {
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 20px;
  }

  .three-col-view {
    grid-gap: 18px;
    -webkit-grid-gap: 18px;
  }


     
    .side-temp-preview .slider__images .badge-active::after{
      line-height: 10px;
    }
}


@media (max-width: 767px) {
  .slider__flex {
    flex-direction: column-reverse;
  }

  .slider__col {
    flex-direction: row;
    align-items: center;
    margin-right: 0;
    margin-top: 24px;
    width: 100%;
  }

  .slider__images {
    width: 100%;
    .badge-active{
          right: 10px;
          font-size: 10px; 
          padding: 2px 6px;
          padding-right: 18px;
          &::before{
            top: 5px;
            width: 8px;
            height: 8px;
          }
    }
  }
.slider__image{
  border-radius: 8px;
      box-shadow: 0px 2px 8px #b5cfff;
}
  .slider__thumbs {
    height: 100px;
    width: calc(100% - 96px);
    margin: 0 16px;
  }

    
  .slider__prev,
  .slider__next {
    height: auto;
    width: 32px;
  }

  .profile-preview-page {
    padding: 0 0 22px;
  }

  .three-col-view {
    // grid-template-columns: minmax(300px, auto) 1fr 1fr;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    -webkit-grid-gap: 20px;
  }

  .color-choose-mobile {
    .color-plates {
      display: none;
    }

  }

  .color-choose-mobile {
    order: 3;
  }

  .color-inner-rounded{
      padding: 5px 19px;
      border-radius: 24px 0 0 24px;
      background: #fff;
      box-shadow: #7159d6 1px 0 4px 0px;
      display: inline-block;
      background-color: #fff;
      position: fixed;
      right: 0;
      top: 92px;
      z-index: 200;
      max-width: 66px;
  }

  .color-choose-mobile {
    .color-closed {
      svg {
        width: 30px;
        height: 30px;
      }
    }

    

    .color-choose-wrap {
      display: block;
      background-color: #fff;
      width: 180px;
      position: fixed;
      right: -180px;
      top: 85px;
      // -webkit-transform: translateY(-50%);
      // -moz-transform: translateY(-50%);
      // transform: translateY(-50%);
      z-index: 999;
      padding: 15px;
      border-radius: 15px;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
      transition: all 0.4s ease-in-out;

      .color-plates {
        // box-shadow: none;
        // border: 0;
        display: block;
        max-height: 300px;
        // overflow-y: auto;
        padding: 0;
        margin-bottom: 0;
      }

      .lebel {
        font-size: 14px;
        line-height: 16px;
      }
    }

    .heading {
      display: none;
    }

    &.show-color-picker {
      .color-choose-wrap {
        right: 0;
      }

    }
  } 


  .name-cols {
    .user-name {
      font-size: 20px;
      line-height: 27px;
      padding-bottom: 7px;
    }
  }
}

@media (max-width: 574px) {
  .preview-final {
    .template-main {
      box-shadow: none;
      border: 0;
      padding: 15px 0 0;
    }

  }

  .temp-lower {
    padding: 15px 10px;
  }

  .color-section {
    box-shadow: none;
    border: 0;
    padding: 0;
    max-width: 375px;
    margin: 0 auto;
  }



}