 
.terms-condition ,
.privacy-policy{
    span{
        font-weight: 400;
        font-size: 16px;
        color: #191919;
        display: block;
        margin-bottom: 15px;
    }

  h1 {
      font-weight: 700;
      font-size: 50px;
      font-family: var(--first-font);
      margin-bottom: 64px;
      color: #191919;
  }
   h3 {
       font-weight: 500;
       font-size: 26px;
       font-family: var(--first-font);
       margin-bottom: 24px;
       color: #191919;
   }
   br{
    display: none;
   }
}
 

@media only screen and (max-width: 767px){
.terms-condition,
    .privacy-policy { 

        h1 {
            
            font-size: 50px; 
            margin-bottom: 40px;
             
        }

        h3 { 
            font-size: 20px; 
            margin-bottom: 20px;
       
        }

         
    }

}
 