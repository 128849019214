.custom-navbar {
  .btn-primary {
    min-width: 96px; 
  }
  .navbar-nav {
    .nav-link {
      color: #1a1b20; 
      padding-left: 20px;
       padding-right: 20px;
      &.active {
        font-weight: 500; 
      background: transparent linear-gradient(270deg, rgba(113, 89, 214, 1) 0%, rgba(6, 185, 187, 1) 100%) 0% 0%;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}

.dashboard-links{
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #000;
  font-size: 14px;
  .icon{
    margin-bottom: 3px;
    svg{
      fill: #000;
    }
  }
  &.active{
    color: #0aaec1;
    .icon{
      svg{
        fill: #0aaec1;
      }
    }
  }
  &:hover, &:focus{
    color: #0aaec1;
    .icon {
        svg {
          fill: #0aaec1;
        }
      }
  }
}

.disabled{
  color: #727272;
  background: #f0f0f0;
  cursor: not-allowed;
  border-radius: 7px;
}

.menu_icon {
  width: 55px;
  height: 40px;
  margin: auto;
  z-index: 10;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: flex-end;
  align-items: flex-end; 
  flex-direction: column;
  justify-content: center;
  padding: 5px 0;
}

.menu_icon span {
  height: 2px;
  width: 60px;
  background: transparent linear-gradient(270deg, #7159d6 0%, #06b9bb 100%) 0%
    0% no-repeat padding-box;
  transform: rotate(0);
  transition: all 200ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-bottom: 7px;
}

span.one {
  top: 4px;
  width: 30px;
}

span.two {
  top: 17px;
  width: 30px;
}

span.three {
  top: 31px;
  width: 30px;
}
.btn-nav {
  border: 0;
  &:focus,
  &:active {
    outline: none;
    border: 0;
    box-shadow: none;
  }
}




// All media start here

// sidenav css here



.sidebar-nav{
    background: #FFFFFF;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    flex: 0 1;
    padding: 30px 16px;
    width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.4s ease-in-out;
    height: 100%;
    margin-right: 14px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px; 
    .navbar-brand{
      margin: 0;
    }

}
.side-nav-wrapper + .main{
  margin-left: auto;
  width: calc(100% - 120px);
}
 

.side-nav-wrapper+.main+.footer {
width: calc(100% - 120px);
  margin-left: auto;
}
.side-items{
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
  .item{
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:last-child{
      margin-bottom: 0;
    }
    // &.active{
    //   .nav-link{
    //     .icon {
    //         svg {
    //           fill: red;
    //           path {
    //             fill: red;
    //           }
    //         }
    //       }
    //   }
    // }
  }
  .nav-link{
    display: flex;
    padding: 0;
    color: #000; 
    text-align: center;
    flex-direction: column;
    font-size: 12px; 
    .icon{
      margin-bottom: 5px;  
    } 

  }
}



.nav-bar-btn {
  display: none;
}
@media only screen and (min-width: 992px) {
  .btn-nav {
    display: none;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1199px){
  .content-image-section{
    overflow: hidden;
    .-mt-44 {
        margin-top: 0;
        padding: 22px 0;
      }
  }
 
  .custom-navbar  {
    .navbar-nav {
      .nav-link{ 
          padding-left: 15px;
          padding-right: 15px;
      }
    } 
  }
}

@media only screen and (max-width: 991px) {
  .custom-navbar {
    margin-top: 0;
    padding: 13px 0;
    height: 100%;
    .navbar-nav {
      .nav-link {
        margin-bottom: 15px;
      }
    }
  }

  .collapse:not(.show) {
    display: flex;
  }
  .navbar-collapse {
    -webkit-transform: translate(-100%);
    transform: translate(-100%);
    will-change: transform;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 320px;
    background: #fff;
    z-index: 9999;
    height: 100vh;
    display: flex;
    -webkit-transition: all 0.2s ease-in-out; 
    &.show-nav {
      transform: translate(0);
      box-shadow: 0 8px 8px 0 rgba(50, 50, 50, 0.4);
    }
  }
  .navbar-collapse {
    align-items: flex-start;
    padding: 30px 0;
    .navbar-nav { 
      width: 100%;
      .btn-groups {
        width: calc(100% - 20px);
        margin: 0 auto;
        margin-top: 18px;
        .btn {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 16px;
          width: 100%;
        }
      }
    }
  }

.language-selection {
  position: relative;
  border: 1px solid #06b9bb;
  border-radius: 5px;
  margin-right: 0;
  width: calc(100% - 20px);
}



     .side-close-btn {
          position: absolute;
          right: 0;
          top: -14px;
          z-index: 15;
          min-width: 60px;
          text-align: center;
          height: 50px;
     }
     
      // .App-header.scrolled {
      //   position: static;
      //   transition: none;
      //   animation: none;
      // } 



      // new changes

      .navbar-brand{
            text-align: right;
            width: 100%;
            margin-right: 0;
      }
      .custom-navbar{
        .btn-nav{
          .menu_icon{
            position: absolute;
            left: 0px;
            top: 15px;
            z-index: 50;
          }
        }
      }
    
}
 


 @media only screen and (max-width: 767px){
// .sidebar-nav{
//       width: 90px;
// }
.side-nav-wrapper+.main { 
  width: 100%;
  margin-left: auto;
  
}
 

.side-nav-wrapper +.main + .footer{ 
      width: 100%;
      margin-left: auto; 
     
}

.side-nav-wrapper{ 
  position: relative;
  
  .sidebar-nav{
    transform: translateX(-140px);
    padding-left: 16px;
    padding-right: 16px;
  }
   
  .nav-bar-btn { 
        position: absolute;
        top: 15px;
        right: 12px;
        z-index: -1;
        display: none;
        height: 60%;
        background: #fff;
  }

  &.show-nav-mobile{
    .sidebar-nav {
      transform: translateX(0); 
      }
    
      .menu_icon{
        span.one {
          top: 20px;
          width: 30px;
          transform: rotate(45deg);
          position: absolute;
        }
          span.two{
            background: transparent;
          }
          span.three {
            top: 20px;
            width: 30px;
            transform: rotate(-45deg);
            position: absolute;
          }
      }
        .nav-bar-btn.btn-nav-01{
          z-index: 50;
          display: block; 
        }
         .btn-nav-02{
            .menu_icon{
              span.one,span.three{
                position: static;
                transform: none;
              }
              span.two{
                background: transparent linear-gradient(270deg, rgba(113, 89, 214, 1) 0%, rgba(6, 185, 187, 1) 100%) 0% 0%;
              }

            }
         }
  }
}
.side-items {
  .nav-link{
    flex-direction: column;
    text-align: center;
  }
} 

.side-nav-wrapper {
  position: relative;
  display: flex; 
  height: 76px; 
}



// new changes css
.mobile-logo-new{
  position: relative; 
}
.nav-bar-btn.btn-nav-01{
  .btn-nav{
    background: #fff;
  }
}
    .nav-bar-btn.btn-nav-02 {
      position: absolute;
      left: -9px;
      top: 15px;
      z-index: 50;
      right: unset !important;
      display: inline-flex !important;
      max-width: 60px;
      height: auto;
    }

 }
@media only screen and (max-width: 575px) {
  .navbar-collapse {
    width: 100%;
  }   

}