 /* SWITCH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
   
  
    .switch-wrapper-plan {
      position: relative;
      display: inline-flex;
      padding: 4px;
      border: 1px solid lightgrey;
      margin-bottom: 40px;
      border-radius: 30px;
      background: #fff;
      width: 100%;
      box-shadow: 0 5px 8px rgba(0, 0, 0, .09);
  
      [type="radio"] {
        position: absolute;
        left: -9999px;
  
        &:checked#free~label[for="free"] {
          color:#fff;
  
          &:hover {
            background: transparent;
          }
        }
  
        &:checked#premium~label[for="premium"] {
          color:#fff; 
          &:hover {
            background: transparent;
          }
        }
  
        &:checked#free+label[for="premium"]~.highlighter {
          transform: none;
        }
  
        &:checked#premium+label[for="free"]~.highlighter {
          transform: translateX(100%);
        } 
      }
  
      label {
    font-size: 18px;
      z-index: 1;
      min-width: 100px;
      line-height: 40px;
        cursor: pointer;
        border-radius: 30px;
        transition: color 0.25s ease-in-out;
            width: 100%; 
      }
  
      .highlighter {
        position: absolute;
        top: 4px;
        left: 4px;
        width: calc(50% - 4px);
        height: calc(100% - 8px);
        border-radius: 30px;
        transition: transform 0.25s ease-in-out; 
        background: transparent linear-gradient(270deg, rgb(113, 89, 214) 0%, rgb(6, 185, 187) 100%) 0% 0% no-repeat padding-box;
        background: transparent -webkit-linear-gradient(270deg, rgb(113, 89, 214) 0%, rgb(6, 185, 187) 100%) 0% 0% no-repeat padding-box;
      }
    }