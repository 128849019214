@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");
.react-loading-skeleton {
  --pseudo-element-display: block !important;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.skeleton-text {
  width: 50%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text__body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}

body {
  font-family: "Poppins";
  color: #1a1b20;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  background-color: #fff;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

p {
  opacity: 1;
  font-size: 18px;
  line-height: 32px;
}

.mb-40 {
  margin-bottom: 40px;
}

.custom-navbar {
  padding: 21px 0;
  transition: all 0.4s;
}

.App-header {
  display: flex;
  flex-direction: column;
}
.App-header.scrolled {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 500;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;
}
.App-header.scrolled .custom-navbar {
  padding: 15px 0;
}

.App-header.scrolled + .main {
  margin-top: 85px;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0px);
  }
}
.btn:focus,
.btn:active:focus {
  box-shadow: none;
  outline: none;
}

.btn-primary {
  background: transparent
    linear-gradient(270deg, rgb(113, 89, 214) 0%, rgb(6, 185, 187) 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
  border: 1px solid #0aaec1;
  font-size: 16px;
  line-height: 26px;
  padding: 10px 24px;
}
.btn-primary:hover {
  background: #fff;
  color: #0aaec1;
  border-color: #0aaec1;
}

.btn-primary.btn-reverse {
  background: #fff;
  color: #0aaec1;
}
.btn-primary.btn-reverse:hover {
  background: transparent
    linear-gradient(270deg, rgb(113, 89, 214) 0%, rgb(6, 185, 187) 100%) 0% 0%
    no-repeat padding-box;
  color: #fff;
}

.heading-main {
  font-size: 72px;
  font-weight: 500;
  line-height: 88px;
}

.heading-medium {
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 16px;
}

.form-control {
  background: #f2f2f2;
  opacity: 1;
  border-width: 1px;
  border-style: solid;
  padding: 12px 20px;
  font-size: 16px;
  color: #9ea0a7;
  border-color: #e1e1e1;
}
.form-control::-moz-placeholder {
  color: #9ea0a7;
}
.form-control::placeholder {
  color: #9ea0a7;
}
.form-control:focus {
  outline: none;
  color: #0aaec1;
  border-color: #0aaec1;
  box-shadow: none;
}

.footer .nav-link {
  color: #252342;
}

.copy-right {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #252342;
}

.breadcrumbs {
  background: #faf9ff;
  padding: 20px 10px;
  margin-bottom: 30px;
}
.breadcrumbs .bread-items {
  font-size: 24px;
  line-height: 35px;
}

.sidebar-bread {
  text-align: left;
  padding: 10px 20px;
}
.sidebar-bread .bread-items {
  font-size: 18px;
  line-height: 30px;
}

.h-100vh {
  height: 100vh;
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
}

.floating-group {
  position: relative;
  margin-bottom: 16px;
}
.floating-group .form-control {
  box-shadow: none;
  border-radius: 0.375rem;
  padding: 15px 20px;
  color: #3a3a3a;
  border-width: 1px;
  border-color: #e1e1e1;
  border-style: solid;
  background-color: #fff;
}
.floating-group .form-control:focus {
  outline: none;
  color: #0aaec1;
  border-color: #0aaec1;
  box-shadow: none;
}
.floating-group .form-control:focus ~ .float-label {
  top: -7px;
  font-size: 12px;
  line-height: 16px;
  color: #6b6b6b;
  background: #fff;
}
.floating-group .float-label {
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 15px;
  top: 14px;
  padding: 0 5px;
  background: none;
  font-size: 14px;
  line-height: 20px;
  color: #6b6b6b;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  white-space: nowrap;
}
.floating-group .form-control:not(:-moz-placeholder-shown) ~ .float-label {
  top: -7px;
  font-size: 12px;
  line-height: 16px;
  color: #6b6b6b;
  background: #fff;
}
.floating-group .form-control:not(:placeholder-shown) ~ .float-label {
  top: -7px;
  font-size: 12px;
  line-height: 16px;
  color: #6b6b6b;
  background: #fff;
}
.floating-group span.info-icon {
  margin-top: -2px;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: none;
}

#react-select-2-listbox {
  background: #fff;
  z-index: 50;
}

.fv-help-block {
  font-size: 14px;
  margin-bottom: 10px;
  margin-top: -6px;
}

.h-auto {
  height: auto !important;
}

.logo.d-flex {
  flex-direction: column;
}

.min-w-160 {
  min-width: 160px !important;
}

@media only screen and (min-width: 992px) {
  .bg-sky-blue {
    background: #d4e9ff;
    opacity: 1;
  }
  .bg-light-blue {
    background: #eae6ff;
  }
}
@media only screen and (max-width: 991px) {
  .mb-40 {
    margin-bottom: 20px;
  }
  .heading-medium {
    font-size: 40px;
    line-height: 52px;
  }
  .breadcrumbs {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }
  .auth-inner .row > * {
    padding-right: 12px;
    padding-left: 12px;
  }
  p {
    font-size: 16px;
    line-height: 28px;
  }
  .heading-medium {
    font-size: 32px;
    line-height: 45px;
  }
  .heading-medium br {
    display: none;
  }
  .copy-right {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    color: #a9a9ae;
  }
  .breadcrumbs .bread-items {
    font-size: 18px;
    line-height: 22px;
  }
  .fs-24 {
    font-size: 24px;
    line-height: 36px;
  }
}
.service-label {
  font-size: 14px;
  line-height: 16px;
}

.master-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.master-container .controls {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-left: -15px;
  margin-right: -15px;
  justify-content: center;
}

.crop-container {
  height: 220px;
  position: relative;
  margin-bottom: 30px;
}

.handle {
  width: 100%;
  padding: 0 30px;
}

.crop-content-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cta {
  background-color: #f60;
  color: #fff;
  font-size: 16px;
  line-height: 26px;
  padding: 10px 24px;
}

.services-fields {
  position: relative;
  z-index: 20;
}

.review-tabel.table > :not(:first-child) {
  border-top: 0;
}

.review-tabel td.r-name {
  width: 30%;
}
.review-tabel td {
  border: 0;
}

.bg-lighter {
  background: #f8fcff;
}

.review-modal .model-header {
  padding: 2rem;
}
.review-modal .modal-body {
  padding: 2rem;
}
.review-modal .form-control {
  padding: 12px 20px;
  margin-bottom: 12px;
}
.review-modal .floating-group {
  margin-bottom: 10px;
}
.review-modal .fv-help-block {
  color: #dc3545;
  font-size: 12px;
}

.tabBtn {
  background: #fff;
  color: rgb(117, 117, 117);
  border: none;
  margin: 0px 5px;
  padding: 0;
  font-weight: 600;
}

.tabBtn.active {
  color: #6079bc;
}

.tabBtn {
  border: 0;
}
.tabBtn:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.loader-overlay .loader-body {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  align-items: center;
  padding: 20px 10px;
  border-radius: 10px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.qr-icon {
  min-width: 70px;
}
.qr-icon svg {
  padding: 6px;
  background-color: #fff;
  border: 1px solid var(--theme05);
  border-radius: 4px;
}

.powered-block {
  font-size: 12px;
  padding-left: 0 !important;
}
.powered-block a {
  color: var(--theme05);
}
.powered-block a.create-link {
  border-radius: 15px;
  border: 1px solid var(--theme05);
  padding: 0 10px;
  transition: all 0.15s ease-in-out;
  white-space: nowrap;
  margin-left: 7px;
}
.powered-block a.create-link:hover,
.powered-block a.create-link:focus {
  color: #fff;
  background-color: var(--theme05);
}

.social-media {
  margin-bottom: 13px !important;
}

.addcontact-btn {
  background-color: #fff;
  box-shadow: none;
  border: 0;
  outline: none;
  border-radius: 3px;
  padding: 3px 8px 4px;
  width: 100%;
  border: 1px solid var(--theme05);
  margin-bottom: 8px;
}
.addcontact-btn:focus,
.addcontact-btn:active,
.addcontact-btn:hover {
  box-shadow: none;
  outline: none;
  background-color: transparent;
}

.social-media-wrap {
  display: flex;
  flex-wrap: wrap;
}
.social-media-wrap .social-media {
  flex: 1;
}

.share-icon-wrap {
  display: flex;
}
.share-icon-wrap .review-button {
  padding-right: 0px !important;
}

@media only screen and (min-width: 576px) {
  .review-modal .modal-dialog {
    max-width: 600px;
  }
}
@media only screen and (max-width: 575px) {
  .review-modal .model-header,
  .review-modal .modal-body {
    padding: 1.2rem;
  }
  .handle {
    padding: 0 14px;
  }
  .tabBtn {
    font-size: 15px;
  }
} 

.custom-bg-row {
  background-color: #191919;
  padding-bottom: 15px;
}
.custom-bg-row .col-lg-4 {
  margin-bottom: 10px; /* Adjust as needed */
}

.custom-bg-row .col-lg-2 {
  margin-bottom: 10px; /* Adjust as needed */
}
.custom-ref-style-card {
  margin-top: 25px;
}
.required {
  color: red;
  margin-left: 05px;
}
.select-country-code {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 60px;
  background-color: #fff;
  color: #333;
}
.select-country-code option {
  background-color: #fff;
  color: #333;
}
.accordion-item:first-of-type {
  margin-bottom: 0px;
}
.material-00dp {
  background-color: white;
  color: #191919;
}
.privacy-policy {
  height: 100%;
  width: 1320px;
}

.privacy-policy .privacy-content span {
  font-weight: 400;
  font-size: 16px;
  color: #191919;
  display: block;
  margin-bottom: 15px;
}
.privacy-policy .privacy-content h1 {
  font-weight: 700;
  font-size: 50px;
  font-family: var(--first-font);
  margin-bottom: 64px;
  color: #191919;
}
.heading-medium {
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 16px;
  text-align: center;
}
.subheading-medium {
  font-size: 30px;
  line-height: 64px;
  margin-bottom: 16px;
}
.circle-skeleton-whatsap {
  width: 30px;
  height: 30px;
  border-radius: 75%;
  margin-left: 15px;
  background-color: #d2d7de;
  animation: loading 2.1s infinite;
  z-index: 9999;
}
.circle-skeleton {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background-color: white;
  animation: loading 2.1s infinite;
  z-index: 9999;
}
@keyframes loading {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.6;
  }
}
.centered-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.rounded-modal {
  border-radius: 20px;
}
