 .two-col-view {
   display: grid;
   grid-template-columns: repeat(2, minmax(0, 1fr));
 }
 .color{
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid rgb(104, 144, 254);
  &.green{
    background-color: green;
  }
 }

 
    
  


    .accordion{
      .floating-group{
        .form-control{
          line-height: 20px;
        }
      }
    }
 

.min-w-300{
  min-width: 300px;
}

 