.user-edit-profile {
  .breadcrumbs {
    margin-bottom: 0;
  }
}
.edit-profile-wrap {
  width: 100%;
  position: relative;
  z-index: 12;
  overflow: hidden;
  padding: 50px 0;

  // &::before{
  //   content: '';
  //   background-image: url(https://cdn.wallpapersafari.com/43/74/aMPRBr.jpg);
  //   background-repeat: no-repeat;
  //   width: 100%;
  //   background-size: cover;
  //   filter: blur(8px);
  //   -webkit-filter: blur(8px);
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: -1;
  //   height: 100%;
  // }
}
.drop-items {
  .btn-primary {
    background-color: transparent;
    // padding: 0;
    // min-width: 0;
    // visibility: hidden;
    // width: 0;
    // border: 0;
  }
}

.edit-profile-wrap {
  .floating-group {
    .form-control {
      height: 48px;
    }
  }
  .media-filed {
    .switch {
      display: none;
    }
    .form-control {
      padding-right: 10px;
    }
  }
}

.upload-profile-sec {
  .img-wrap {
    width: 150px;
    height: 150px;
    // border: 2px solid #6a62d4;
    padding: 2px;
    border-radius: 50%;
    box-shadow: 0px 2px 6px rgb(0 0 0 / 5%);
  }
  .edit-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 12;
    cursor: pointer;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #6a62d4;
    border-radius: 50%;
    padding: 8px;
    border: 2px solid #6a62d4;
    path {
      fill: #fff;
    }
  }
}

#dropdown-split-basic {
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  &:focus,
  &:active {
    outline: none;
    border: 0;
    box-shadow: none;
  }
  &::after {
    display: inline-block;
    margin-left: 0.5em;
    content: "";
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    border: 0;
    background-image: url("../templates/images/icons/angle.svg");
    background-size: cover;
    width: 24px;
    height: 24px;
  }
}

.dropdown-menu {
  a,
  .dropdown-item {
    &:active,
    &:focus {
      background: #6a62d4;
      color: #fff;
      svg {
        path {
          fill: #fff;
        }

        fill: #fff;
      }
    }
  }
  .dropdown-item {
    font-size: 14px;
    line-height: 16px;
    a {
      &:focus,
      &:active {
        svg {
          path {
            fill: #fff;
          }

          fill: #fff;
        }
      }
    }
    &:focus {
      outline: none;
      color: #fff;
      a {
        color: #fff;
      }
      svg {
        path {
          fill: #fff;
        }
        fill: #fff;
      }
    }
  }
}
.mobile-link,
.user-p a {
  color: #313131;
}

@media only screen and (min-width: 992px) {
  .edit-basic-info {
    padding-right: 20px;
  }
  .upload-profile-sec {
    padding-left: 20px;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    left: unset;
    right: 0;
  }
}
@media only screen and (max-width: 992px) {
  .mobile-link {
    margin-bottom: 25px;
    text-align: center;
  }
}
